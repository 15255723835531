import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import {
  faCirclePlus,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import Label from '../../components/atoms/Label/Label';
import { getDateFormat } from '../../utils/format/dataFormat';
import {
  getSecurityColor,
  getSecurityText,
} from '../../utils/functions/securitySeverity';
import { useTranslation } from '../../i18n';
import SCPartnerClients from './PartnerClients.style';
import { SUBSCRIPTIONS_PRODUCTS } from '../../utils/constants/subscription';
import usePartnerClients from '../../hooks/usePartnerClients/usePartnerClients';
import PartnerClientControlPanelSummary from '../../components/organisms/PartnerClientControlPanelSummary/PartnerClientControlPanelSummary';
import SectionCard from '../../components/molecules/SectionCard/SectionCard';
import Input from '../../components/atoms/Input/Input';
import getCompanyIDExample from '../../utils/internationalizationModules/companyIdLabels/companyIdLabels';
import { isNonCountryEnvironment } from '../../utils/locale';
import Button from '../../components/atoms/Button/Button';
import { PARTNER_TYPES } from '../../utils/constants/partners';
import { showPopUp } from '../../redux/actions/popUp.actions';
import { ERRORS } from '../../utils/constants/errors';
import { ErrorKey } from '../../track';
import subscriptionsService from '../../api/services/subscriptions.service';
import { downloadBlob } from '../../utils/functions/download';
import usePartnerConfig from '../../hooks/usePartnerConfig/usePartnerConfig';
import SelectPartnerCollaborator from '../../components/molecules/SelectPartnerCollaborators/SelectPartnerCollaborator';
import clientsService from '../../api/services/clients.service';

export const PARTNER_CLIENT_SUBSCRIPTIONS_STATUS = {
  expired: 'EXPIRED',
  paying: 'ACTIVE',
  trial: 'TRIAL',
};

export const getSubscriptionStatus = (partnerClientStatus) => {
  if (
    partnerClientStatus.active < new Date() ||
    partnerClientStatus.serviceLevelIDs[0] === SUBSCRIPTIONS_PRODUCTS.DEFAULT
  ) {
    return PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;
  }

  return (
    PARTNER_CLIENT_SUBSCRIPTIONS_STATUS[
      partnerClientStatus.serviceLevelIDs[0]
    ] ?? PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
  );
};

const PartnerClients = () => {
  const i18n = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputFilter, setInputFilter] = useState();
  const [isExporting, setIsExporting] = useState(false);

  const clientIdFromUrl = searchParams.get('clientId');
  const collaboratorIdFromUrl = searchParams.get('collaboratorId');

  const { isPartnerType, isParentPartner } = usePartnerConfig();

  const { partnerClients, loading, fetchPartnerClients } = usePartnerClients({
    partnerId: collaboratorIdFromUrl,
  });

  const filteredPartnerClients = inputFilter
    ? partnerClients.filter(({ company }) =>
        [company.name, company.cif].some((item) =>
          item.toLowerCase().includes(inputFilter.toLowerCase())
        )
      )
    : partnerClients;

  const canSeeScore = isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);

  const getValueLabel = (score) => {
    if (!score) {
      return i18n.t('websiteSecurity.unknown');
    }

    const formattedScore = Math.round(score * 10) / 10;
    const securityText = getSecurityText(score);

    return `${i18n.t(`websiteSecurity.${securityText}`)} (${formattedScore})`;
  };

  const sortDirections = ['ascend', 'descend', 'ascend'];

  const columns = [
    {
      title: i18n.t('partner.table.headers.name'),
      key: 'companyName',
      dataIndex: 'companyName',
      sortDirections,
      sorter: (
        { companyName: aCompanyName },
        { companyName: bCompanyName }
      ) => {
        return aCompanyName.localeCompare(bCompanyName);
      },
      render: (text) => text || '-',
    },
    // Si es un entorno de país, se introduce la columna 'companyCif'
    ...(!isNonCountryEnvironment
      ? [
          {
            title: getCompanyIDExample().name,
            key: 'companyCif',
            dataIndex: 'companyCif',
            sortDirections,
            sorter: (
              { companyCif: aCompanyCif },
              { companyCif: bCompanyCif }
            ) => {
              return aCompanyCif.localeCompare(bCompanyCif);
            },
            render: (text) => text || '-',
          },
        ]
      : []),
    ...(canSeeScore
      ? [
          {
            title: i18n.t('partner.table.headers.score'),
            key: 'score',
            dataIndex: 'score',
            width: 132,
            sortDirections,
            sorter: (a, b) => a.score - b.score,
            render: (text) => (
              <Label
                value={getValueLabel(text)}
                color={getSecurityColor(text)}
              />
            ),
          },
        ]
      : []),
    {
      title: i18n.t('partner.table.headers.subscriptionStatus'),
      key: 'subscriptionStatus',
      dataIndex: 'subscriptionStatus',
      sortDirections,
      sorter: (
        { subscriptionStatus: subscriptionStatusA },
        { subscriptionStatus: subscriptionStatusB }
      ) => {
        const subscriptionStatusAText =
          getSubscriptionStatus(subscriptionStatusA);
        const subscriptionStatusBText =
          getSubscriptionStatus(subscriptionStatusB);

        return i18n
          .t(`partner.table.body.${subscriptionStatusAText}`)
          .localeCompare(
            i18n.t(`partner.table.body.${subscriptionStatusBText}`)
          );
      },
      render: (text) => {
        const subscriptionStatus = getSubscriptionStatus(text);

        const isSubscriptionExpired =
          subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;

        return (
          <p
            style={{
              fontSize: '14px',
              color: isSubscriptionExpired && 'var(--red)',
              fontWeight: isSubscriptionExpired && '600',
            }}>
            {i18n.t(`partner.table.body.${subscriptionStatus}`)}
          </p>
        );
      },
    },
    {
      title: i18n.t('partner.table.headers.licenses'),
      children: [
        {
          title: i18n.t('partner.table.headers.malware'),
          key: 'malwareLicenses',
          dataIndex: 'malwareLicenses',
          sortDirections,
          sorter: (a, b) => a.malwareLicenses - b.malwareLicenses,
        },
        {
          title: i18n.t('partner.table.headers.web'),
          key: 'webLicenses',
          dataIndex: 'webLicenses',
          sortDirections,
          sorter: (a, b) => a.webLicenses - b.webLicenses,
        },
        {
          title: i18n.t('partner.table.headers.email'),
          key: 'mailboxLicenses',
          dataIndex: 'mailboxLicenses',
          sortDirections,
          sorter: (a, b) => a.mailboxLicenses - b.mailboxLicenses,
        },
      ],
    },
    {
      title: i18n.t('controlPanel.widgetSubscription.subscriptionPeriod'),
      key: 'expiredAt',
      dataIndex: 'expiredAt',
      sortDirections,
      sorter: (
        { subscriptionStatus: subscriptionStatusA },
        { subscriptionStatus: subscriptionStatusB }
      ) => {
        const subscriptionStatusAText =
          getSubscriptionStatus(subscriptionStatusA);
        const subscriptionStatusBText =
          getSubscriptionStatus(subscriptionStatusB);

        const subscriptionStatusATimestamp =
          subscriptionStatusAText ===
          PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
            ? 0
            : subscriptionStatusA.active;
        const subscriptionStatusBTimestamp =
          subscriptionStatusBText ===
          PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired
            ? 0
            : subscriptionStatusB.active;

        return subscriptionStatusATimestamp - subscriptionStatusBTimestamp;
      },
      render: (text, record) => {
        const subscriptionStatus = getSubscriptionStatus(
          record.subscriptionStatus
        );

        const isSubscriptionExpired =
          subscriptionStatus === PARTNER_CLIENT_SUBSCRIPTIONS_STATUS.expired;

        return !isSubscriptionExpired ? getDateFormat(new Date(text)) : '-';
      },
    },
  ];

  const data = filteredPartnerClients.map(
    ({ id, company, scoring, status, subscription }) => ({
      id,
      companyName: company.name,
      companyCif: company.cif,
      score: scoring.lastValue,
      subscriptionStatus: status,
      malwareLicenses: subscription.licenses.malwareProtection,
      webLicenses: subscription.licenses.webProtection,
      mailboxLicenses: subscription.licenses.emailProtection,
      expiredAt: status.nextRenewalAt,
    })
  );

  const handleInputFilter = (inputValue) => {
    setInputFilter(inputValue);
  };

  const getRowClassName = ({ id }) => {
    return `${id === clientIdFromUrl ? 'selected-row' : ''} cursor-pointer`;
  };

  const handleCreateClient = async (formValues) => {
    try {
      await clientsService.addPartnerClient(formValues);
      dispatch(showPopUp(null));
      fetchPartnerClients();
    } catch (error) {
      const { error: errorCode } = error.response.data;

      const i18nKeys = {
        [ERRORS.INVALID_DOMAIN]: 'websiteSecurity.add.errorDomainFormat',
        [ERRORS.INVALID_EMAIL]: 'errors.wrongEmailFormatShort',
        [ERRORS.CLIENT_ALREADY_EXIST]: 'setup.company.genericError',
        [ERRORS.MEMBER_ALREADY_EXIST]: 'errors.memberAlreadyExist',
      };

      const i18nKey = i18nKeys[errorCode] ?? 'errors.not_found';

      ErrorKey(i18nKey);
    }
  };

  const handleExportSubscriptions = async () => {
    setIsExporting(true);

    try {
      const resp = await subscriptionsService.exportPartnerClientSubscriptions({
        partnerId: collaboratorIdFromUrl,
      });
      const date = moment(new Date()).format('YYYY-MM-DD');
      const fileName = `${date}_partner_licenses.xlsx`;

      downloadBlob(resp, fileName);
    } catch (e) {
      dispatch(
        showPopUp('notification', {
          notificationType: 'error',
          title: i18n.t('common.error'),
          text: i18n.t('errors.not_found'),
        })
      );
    } finally {
      setIsExporting(false);
    }
  };

  const displayControlPanel = partnerClients.length > 0 && canSeeScore;
  const canAddClient =
    isPartnerType([PARTNER_TYPES.MSSP, PARTNER_TYPES.RESELLER]) &&
    !isParentPartner;

  return (
    <SCPartnerClients showFull={!displayControlPanel}>
      <SectionCard className="table-container">
        <div className="input-container">
          <div className="filters-container">
            <Input
              inputType="text"
              inputPlaceholder={`${i18n.t('common.search')}...`}
              onChangeValue={handleInputFilter}
              icon={faMagnifyingGlass}
              size="short"
              design="round"
              disabled={loading}
            />
            {isParentPartner && (
              <SelectPartnerCollaborator
                value={collaboratorIdFromUrl}
                onChange={(selectedCollaboratorId) => {
                  if (!selectedCollaboratorId) {
                    setSearchParams();
                  } else {
                    setSearchParams({ collaboratorId: selectedCollaboratorId });
                  }
                }}
              />
            )}
          </div>

          <div className="filters-container">
            <Tooltip
              title={i18n.t('partner.table.subscriptionMaintenanceTooltip')}>
              <Button
                text={i18n.t('partner.table.subscriptionMaintenance')}
                icon={faDownload}
                color="bluishGrey"
                onClick={handleExportSubscriptions}
                size="fit"
                loading={isExporting}
              />
            </Tooltip>

            {canAddClient && (
              <Button
                icon={faCirclePlus}
                text={i18n.t('partner.table.newClient')}
                color="bluishGrey"
                size="small"
                onClick={() =>
                  dispatch(
                    showPopUp('addClientFromPartner', handleCreateClient)
                  )
                }
                disabled={loading}
              />
            )}
          </div>
        </div>

        <Table
          pagination={false}
          scroll={{ y: 560, x: 950 }}
          {...(canSeeScore && {
            onRow: (record) => {
              return {
                onClick: () => {
                  setSearchParams((prev) => {
                    prev.set('clientId', record.id);
                    return prev;
                  });
                },
              };
            },
            rowClassName: getRowClassName,
          })}
          bordered
          columns={columns}
          dataSource={data}
          loading={loading}
        />
      </SectionCard>

      {displayControlPanel && (
        <PartnerClientControlPanelSummary
          clientId={clientIdFromUrl}
          fetchPartnerClients={fetchPartnerClients}
          className="partner-clients-summary"
        />
      )}
    </SCPartnerClients>
  );
};

export default PartnerClients;
