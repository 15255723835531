import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from '../../i18n';
import { showPopUp } from '../../redux/actions/popUp.actions';
import clientsService from '../../api/services/clients.service';
import usePartnerConfig from '../usePartnerConfig/usePartnerConfig';
import { PARTNER_TYPES } from '../../utils/constants/partners';

const usePartnerClients = ({ partnerId } = {}) => {
  const dispatch = useDispatch();
  const i18n = useTranslation();
  const [partnerClients, setPartnerClients] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const { isPartnerType } = usePartnerConfig();

  const canSeeScore = isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);

  useEffect(() => {
    fetchPartnerClients();
  }, [partnerId]);

  const fetchPartnerClients = () => {
    setLoading(true);
    clientsService
      .getPartnerClients({ partnerId })
      .then((partnerClientsResp) => {
        const orderedPartnerClients = partnerClientsResp.data?.data.sort(
          (a, b) =>
            b.subscription.licenses.malwareProtection -
            a.subscription.licenses.malwareProtection
        );
        if (
          orderedPartnerClients?.[0] &&
          !searchParams.get('clientId') &&
          canSeeScore
        ) {
          setSearchParams((prev) => {
            prev.set('clientId', orderedPartnerClients?.[0].id);
            return prev;
          });
        }
        setPartnerClients(orderedPartnerClients);
      })
      .catch(() => {
        dispatch(
          showPopUp('notification', {
            notificationType: 'error',
            title: i18n.t('common.error'),
            text: i18n.t('errors.not_found'),
            closeButtonText: i18n.t('common.close'),
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    partnerClients,
    fetchPartnerClients,
    loading,
  };
};

export default usePartnerClients;
