import React from 'react';
import { faBadgeCheck } from '@fortawesome/pro-regular-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { Skeleton } from 'antd';
import { useTranslation } from '../../../i18n';
import SectionCard from '../../molecules/SectionCard/SectionCard';
import { SUBSCRIPTIONS_PRODUCTS } from '../../../utils/constants/subscription';
import { getDateFormat } from '../../../utils/format/dataFormat';
import SCMySubscriptionCard from './MySubscriptionCard.style';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import LabelDetailGroup from '../LabelDetailGroup/LabelDetailGroup';
import YourSubscriptionCardInfoBanner from '../../molecules/YourSubscriptionCardInfoBanner/YourSubscriptionCardInfoBanner';
import usePartnerConfig from '../../../hooks/usePartnerConfig/usePartnerConfig';

const MySubscriptionCard = ({
  subscriptionProduct,
  subscriptionExpirationDate,
  nextRenewalAt,
  nextPaymentAt,
  showInfoBanner,
  showLinkToPlans,
  isHeaderClickable,
  loading = false,
  className,
}) => {
  const i18n = useTranslation();
  const navigate = useNavigate();

  const { isPartnerType } = usePartnerConfig();

  const canSeeNextInvoice = isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);

  const SUBSCRIPTION_STATUS_TEXTS = {
    [SUBSCRIPTIONS_PRODUCTS.DEFAULT]: i18n.t(
      'controlPanel.widgetSubscription.inactive'
    ),
    [SUBSCRIPTIONS_PRODUCTS.TRIAL]: i18n.t(
      'controlPanel.widgetSubscription.trialActive'
    ),
    [SUBSCRIPTIONS_PRODUCTS.PAYING]: i18n.t(
      'controlPanel.widgetSubscription.active'
    ),
  };

  const getLabelDetails = () => {
    const labelDetails = [
      {
        name: i18n.t('controlPanel.widgetSubscription.status'),
        value: SUBSCRIPTION_STATUS_TEXTS[subscriptionProduct],
      },
    ];

    if (subscriptionProduct === SUBSCRIPTIONS_PRODUCTS.TRIAL) {
      labelDetails.push({
        name: i18n.t('controlPanel.widgetSubscription.trialPeriod'),
        value: getDateFormat(subscriptionExpirationDate),
      });
    }

    if (subscriptionProduct === SUBSCRIPTIONS_PRODUCTS.PAYING) {
      labelDetails.push({
        name: i18n.t('controlPanel.widgetSubscription.subscriptionPeriod'),
        value: getDateFormat(nextRenewalAt),
      });

      if (!canSeeNextInvoice) {
        labelDetails.push({
          name: i18n.t('controlPanel.widgetSubscription.nextInvoice'),
          value: getDateFormat(nextPaymentAt),
        });
      }
    }

    return labelDetails;
  };

  return (
    <SCMySubscriptionCard className={className}>
      <SectionCard
        headerIcon={faBadgeCheck}
        headerTitle={i18n.t('controlPanel.widgetSubscription.title')}
        headerButtonOnClick={
          isHeaderClickable
            ? () => navigate('/client/settings/billing')
            : undefined
        }>
        {!loading ? (
          <div className="subscription-card-container">
            <LabelDetailGroup labelDetails={getLabelDetails()} />

            {showInfoBanner && <YourSubscriptionCardInfoBanner />}

            {showLinkToPlans && (
              <Link to="/license-plan-features">
                {i18n.t('licensePlanFeatures.linkText')}
              </Link>
            )}
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 3 }} />
        )}
      </SectionCard>
    </SCMySubscriptionCard>
  );
};
export default MySubscriptionCard;
