import React from 'react';
import { useParams } from 'react-router-dom';
import { faCompass, faLaptopMobile } from '@fortawesome/pro-regular-svg-icons';
import SCPartnerClientControlPanel from './PartnerClientControlPanel.style';
import SectionLayout from '../../components/layouts/SectionLayout/SectionLayout';
import ProtectionLicensesCard from '../../components/organisms/DeviceSecurityCard/ProtectionLicensesCard';
import usePartnerClient from '../../hooks/usePartnerClient/usePartnerClient';
import BreachNotificationCard from '../../components/organisms/BreachNotificationCard/BreachNotificationCard';
import WebsiteSecurityCard from '../../components/organisms/WebsiteSecurityCard/WebsiteSecurityCard';
import EmailSecurityCard from '../../components/organisms/EmailSecurityCard/EmailSecurityCard';
import BackButton from '../../components/molecules/BackButton/BackButton';
import DomainImpersonationCard from '../../components/organisms/DomainImpersonationCard/DomainImpersonationCard';
import SupplierSecurityCard from '../../components/organisms/SupplierSecurityCard/SupplierSecurityCard';
import PhishingTrainingCard from '../../components/organisms/PhishingTrainingCard/PhishingTrainingCard';
import MySubscriptionCard from '../../components/organisms/PartnerClientMySubscriptionCard/MySubscriptionCard';
import PartnerClientControlPanelHeader from '../../components/molecules/PartnerClientControlPanelHeader/PartnerClientControlPanelHeader';
import usePartnerClientSubscription from '../../hooks/usePartnerClientSubscription/usePartnerClientSubscription';
import PurchasedLicenses from '../../components/organisms/PurchasedLicenses/PurchasedLicenses';
import { useTranslation } from '../../i18n';
import usePartnerClientLicensesCounter from '../../hooks/usePartnerClientLicensesCounter/usePartnerClientLicensesCounter';
import { getLicensesByType } from '../../utils/functions/licenses';
import MailboxProtectionCard from '../../components/organisms/MailboxProtectionCard/MailboxProtectionCard';
import useGetPartnerInfo from '../../hooks/useGetPartnerInfo/useGetPartnerInfo';

const PartnerClientControlPanel = () => {
  const { clientId } = useParams();
  const i18n = useTranslation();

  const {
    partnerClient,
    loading: partnerClientLoading,
    fetchPartnerClient,
  } = usePartnerClient({
    clientId,
  });
  const {
    licensesCounter,
    loading: licensesCounterLoading,
    fetchPartnerClientLicensesCounter,
  } = usePartnerClientLicensesCounter({ clientId });
  const {
    subscription,
    loading: subscriptionLoading,
    fetchPartnerClientSubscription,
  } = usePartnerClientSubscription({ clientId });
  const { partnerInfo, loading: partnerInfoLoading } = useGetPartnerInfo({
    clientId,
  });

  const { licenses } = subscription;
  const { licensesSent, licensesUsed } = licensesCounter;

  const { malwareProtectionLicenses, webProtectionLicenses } =
    getLicensesByType(licenses, licensesSent, licensesUsed);

  const fetchData = () => {
    fetchPartnerClient();
    fetchPartnerClientLicensesCounter();
    fetchPartnerClientSubscription();
  };

  return (
    <SectionLayout>
      <SCPartnerClientControlPanel>
        <>
          <div className="section-back-button">
            <BackButton />
          </div>

          <div className="section-organism">
            <PartnerClientControlPanelHeader
              partnerClient={{ ...partnerClient, subscription, partnerInfo }}
              fetchPartnerClientsData={fetchData}
              loading={
                partnerClientLoading ||
                licensesCounterLoading ||
                subscriptionLoading ||
                partnerInfoLoading
              }
            />
          </div>

          <div className="control-panel-cards-container">
            <MySubscriptionCard
              subscriptionProduct={partnerClient?.status?.serviceLevelIDs[0]}
              subscriptionExpirationDate={
                new Date(partnerClient?.status?.active)
              }
              nextRenewalAt={new Date(partnerClient?.status?.nextRenewalAt)}
              nextPaymentAt={new Date(partnerClient?.status?.nextPaymentAt)}
              loading={partnerClientLoading}
            />

            <PurchasedLicenses
              licenses={licenses}
              loading={subscriptionLoading}
            />

            <ProtectionLicensesCard
              headerIcon={faLaptopMobile}
              headerTitle={i18n.t(
                'controlPanel.protectionLicenses.deviceProtectionTitle'
              )}
              data={{
                total: licenses?.malwareProtection,
                ...malwareProtectionLicenses,
              }}
              loading={licensesCounterLoading || subscriptionLoading}
            />

            <ProtectionLicensesCard
              headerIcon={faCompass}
              headerTitle={i18n.t(
                'controlPanel.protectionLicenses.webProtectionTitle'
              )}
              data={{
                total: licenses?.webProtection,
                ...webProtectionLicenses,
              }}
              loading={licensesCounterLoading || subscriptionLoading}
            />

            <MailboxProtectionCard
              data={{
                isProtected: partnerClient?.protection?.email,
                protectedMailboxes:
                  partnerClient?.status?.protectedMailboxes?.quantity,
                totalMailboxes: licenses?.emailProtection,
                lastUpdateAt: new Date(
                  partnerClient?.status?.protectedMailboxes?.updatedAt
                ),
              }}
              loading={partnerClientLoading || subscriptionLoading}
            />

            <EmailSecurityCard
              data={partnerClient?.status?.emailSecurity}
              atEmail={partnerClient?.atEmail}
              loading={partnerClientLoading}
            />

            <WebsiteSecurityCard
              data={{
                mainMonitoredDomain: partnerClient?.monitoredDomains?.[0],
                ...partnerClient?.status?.webSecurity,
              }}
              loading={partnerClientLoading}
            />

            <DomainImpersonationCard
              monitoredDomainsLength={partnerClient?.monitoredDomains?.length}
              data={partnerClient?.status?.webSpoofing}
              loading={partnerClientLoading}
            />

            <BreachNotificationCard
              data={partnerClient?.status?.dataLeaks}
              loading={partnerClientLoading}
            />

            <PhishingTrainingCard
              data={partnerClient?.status?.phishingTraining}
              loading={partnerClientLoading}
            />

            <SupplierSecurityCard
              data={partnerClient?.status?.suppliersSecurity}
              loading={partnerClientLoading}
            />
          </div>
        </>
      </SCPartnerClientControlPanel>
    </SectionLayout>
  );
};

export default PartnerClientControlPanel;
