import api from '../config';
import { CLIENTS_API } from '../paths';

const updateWelcomePopup = (data) => {
  return api.put(`${CLIENTS_API}/welcome-popup`, data);
};

const addSupplier = (data) => {
  return api.post(`${CLIENTS_API}/suppliers`, data);
};

const editSupplier = (domain, data) => {
  return api.put(`${CLIENTS_API}/suppliers`, data, {
    params: { id: domain },
  });
};

const getPartnerClient = (clientId) => {
  return api.get(`${CLIENTS_API}/partners/clients/${clientId}`);
};

const getPartnerData = (licensee) => {
  return api.get(`${CLIENTS_API}/partners/data`, {
    params: {
      licensee,
    },
  });
};

const addPartnerClient = (data) => {
  return api.post(`${CLIENTS_API}/partners/clients`, data);
};

const addPartnerCollaborator = (data) => {
  return api.post(`${CLIENTS_API}/partners/children`, data);
};

const getPartnerStatistics = (data) => {
  return api.get(`${CLIENTS_API}/partners/statistics`, {
    params: data,
  });
};

const getAllPartnerCollaborators = ({ signal }) => {
  return api.get(`${CLIENTS_API}/partners/all-children`, { signal });
};

const getPartnerCollaborators = () => {
  return api.get(`${CLIENTS_API}/partners/children`);
};

const getPartnerClients = ({ partnerId }) => {
  return api.get(`${CLIENTS_API}/partners/clients`, {
    params: {
      partnerId,
    },
  });
};

const getPartnerInfo = ({ clientId }) => {
  return api.get(`${CLIENTS_API}/partners/info/${clientId}`);
};

export default {
  updateWelcomePopup,
  addSupplier,
  editSupplier,
  getPartnerClient,
  getPartnerData,
  addPartnerClient,
  addPartnerCollaborator,
  getPartnerStatistics,
  getAllPartnerCollaborators,
  getPartnerCollaborators,
  getPartnerClients,
  getPartnerInfo,
};
