import React from 'react';

import { useSelector } from 'react-redux';
import SCProfileBilling from './ProfileBilling.style';
import SectionLayout from '../../layouts/SectionLayout/SectionLayout';
import SectionHeader from '../../molecules/SectionHeader/SectionHeader';
import MySubscriptionCard from '../PartnerClientMySubscriptionCard/MySubscriptionCard';
import LICENSEES from '../../../utils/constants/licensees';
import { SUBSCRIPTIONS_PRODUCTS } from '../../../utils/constants/subscription';
import ManageSubscription from '../ManageSubscription/ManageSubscription';
import BillingHistory from '../BillingHistory/BillingHistory';
import { isNonCountryEnvironment } from '../../../utils/locale';
import { PARTNER_TYPES } from '../../../utils/constants/partners';
import { hasPaymentAccess } from '../../../utils/functions/subscriptions';
import PurchasedLicenses from '../PurchasedLicenses/PurchasedLicenses';
import useLicenses from '../../../hooks/licenses/useLicenses';
import usePartnerConfig from '../../../hooks/usePartnerConfig/usePartnerConfig';

const ProfileBilling = () => {
  const client = useSelector((redux) => redux.client);
  const subscription = useSelector((redux) => redux.subscription);
  const { licenses } = useLicenses();

  const { isPartnerType } = usePartnerConfig();

  const canManageSubscription = isPartnerType([
    PARTNER_TYPES.MSSP,
    PARTNER_TYPES.RESELLER,
  ]);

  return (
    <SectionLayout>
      <SCProfileBilling>
        <div className="section-organism">
          <SectionHeader />
        </div>

        <div className="section-organism multi-column">
          <MySubscriptionCard
            subscriptionProduct={client?.status?.serviceLevelIDs[0]}
            subscriptionExpirationDate={client?.status?.active.toDate()}
            nextRenewalAt={client?.status?.nextRenewalAt?.toDate()}
            nextPaymentAt={client?.status?.nextPaymentAt?.toDate()}
            showLinkToPlans={client.licensee === LICENSEES.santander}
            showInfoBanner={hasPaymentAccess()}
          />
          <PurchasedLicenses licenses={licenses} />
        </div>

        {subscription?.product === SUBSCRIPTIONS_PRODUCTS.PAYING &&
          !isNonCountryEnvironment &&
          !canManageSubscription && (
            <div className="section-organism">
              <ManageSubscription />
            </div>
          )}

        {!isNonCountryEnvironment && !canManageSubscription && (
          <BillingHistory />
        )}
      </SCProfileBilling>
    </SectionLayout>
  );
};

export default ProfileBilling;
